import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './JobListings.css';
import AnimatedEllipsis from './AnimatedEllipsis';
import JobTableContainer from './JobTableContainer';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet'; // Add this import

const CITIES = [
  { name: 'Austin', state: 'TX' },
  { name: 'Los Angeles', state: 'CA' },
  { name: 'Miami', state: 'FL' },
  { name: 'New York', state: 'NY' },
  { name: 'San Francisco', state: 'CA' },
  { name: 'Seattle', state: 'WA' }
];

const TOP_COMPANIES = [
  'Adobe',
  'Airbnb',
  'Amazon',
  'Apple',
  'Dropbox',
  'Google',
  'LinkedIn',
  'Meta',
  'Microsoft',
  'Netflix',
  'Ramp',
  'Salesforce',
  'Slack',
  'Spotify',
  'Twitter',
  'Uber'
];

const JOBS_PER_PAGE = 100; // Maximum allowed by the API

// Add this constant at the top of your file, outside the component
const HARD_CODED_JOBS = [
  {
    id: '4238943225',
    title: 'Director, Product Design',
    company: 'Ramp',
    location: 'New York, NY',
    datePosted: '',
    salaryRange: '199.8K – $290K',
    employmentType: 'Full-time',
    url: 'https://jobs.ashbyhq.com/ramp/6f0fac1d-9469-43d1-8dff-09c07ff208bd',
    isHardCoded: true,  // Add this flag
    description: `Ramp is building the next generation of finance tools—from corporate cards and expense management, to bill payments and accounting integrations—designed to save businesses time and money with every click. Over 12,000 businesses are spending an average of 3.3% less and closing their books 8 times faster, thanks to Ramp's finance automation platform that enables billions of dollars of purchases each year.

Founded in 2019, Ramp has seen nearly 10x year-over-year growth which has led to a valuation of $8.1 billion in just over 3 years. Its investors include Founders Fund, Stripe, Citi, Goldman Sachs, Thrive Capital, 137 Ventures, and Coatue, as well as over 100 angel investors who were founders or executives of companies like Facebook, Google, Amazon, Uber, and Stripe. The Ramp team comprises talented leaders from some of the leading financial services and fintech companies—Capital One, Stripe, Affirm, Goldman Sachs, American Express, Visa—as well as high-growth technology companies like Facebook, Spotify, Zenefits, Uber, Dropbox, and Instacart. Recently named America's No. 2 Best Startup Employer, Ramp is strategically positioned to continue its hypergrowth.

About the Role
We're looking for a Director of Product Design to lead our design team and help shape the future of financial software. You'll be responsible for building and leading a world-class design team, setting the vision for our product design, and working closely with our product and engineering teams to deliver exceptional user experiences.

What You'll Do
• Lead and grow a team of talented product designers, setting the bar for design excellence across the company
• Develop and implement design strategies that align with Ramp's business goals and user needs
• Collaborate with product managers, engineers, and other stakeholders to define product requirements and create innovative solutions
• Establish and maintain design systems and processes to ensure consistency and efficiency across all Ramp products
• Mentor and coach designers to help them grow their skills and advance their careers
• Contribute to high-level product strategy discussions and help shape the future direction of Ramp's products
• Foster a culture of user-centered design and continuous improvement within the design team and across the company

What You Need
• 8+ years of experience in product design, with at least 3 years in a leadership role
• Strong portfolio demonstrating your ability to lead complex design projects from concept to launch
• Experience building and leading design teams in a fast-paced, high-growth environment
• Deep understanding of user-centered design principles and methodologies
• Excellent communication and presentation skills, with the ability to articulate design decisions to both technical and non-technical audiences
• Strong project management skills and ability to prioritize multiple projects simultaneously
• Experience with design systems and a track record of implementing them successfully
• Familiarity with financial products and services is a plus

Nice to Haves
• Experience in fintech or B2B SaaS products
• Knowledge of data visualization and financial reporting
• Familiarity with user research methodologies and tools
• Experience with design tools such as Figma, Sketch, and Adobe Creative Suite

Ramp Benefits (for U.S. based employees)
• 100% medical, dental & vision insurance coverage for you
• Partially covered insurance for your dependents
• 401k (including employer match)
• Unlimited PTO
• Annual company offsite
• Work from home setup stipend
• Wellness stipend

Ramp is an equal opportunity employer. We deeply value diversity and are committed to creating an inclusive environment for all employees.`,
  },

  {
    id: '545jt9459t',
    title: 'Senior Product Designer',
    company: 'Ramp',
    location: 'New York, NY',
    datePosted: '',
    salaryRange: '165.8K – $195K',
    employmentType: 'Full-time',
    url: 'https://jobs.ashbyhq.com/ramp/39d302da-b017-4f18-b6ad-f0e77d024e2c',
    isHardCoded: true,
    description: `Ramp is a financial operations platform designed to save businesses time and money. Combining corporate cards with expense management, bill payments, vendor management, accounting automation, and more, Ramp's all-in-one solution frees finance teams to do the best work of their lives. More than 25,000 companies, from family-owned farms to e-commerce giants to space startups, have saved $1B and 10M hours with Ramp. Founded in 2019, Ramp powers the fastest-growing corporate card and bill payment platform in America, and enables over 35 billion dollars in purchases each year.

Ramp's investors include Sequoia, Founders Fund, Thrive Capital, Khosla Ventures, Greylock, Stripe, Goldman Sachs, Coatue, and Redpoint, as well as over 100 angel investors who were founders or executives of leading companies. The Ramp team comprises talented leaders from leading financial services and fintech companies—Stripe, Affirm, Goldman Sachs, American Express, Mastercard, Visa, Capital One—as well as technology companies such as Meta, Uber, Netflix, Twitter, Dropbox, and Instacart.

Ramp has been named to Fast Company's Most Innovative Companies list and LinkedIn's Top U.S. Startups for over 3 years, as well as the Forbes Cloud 100, CNBC Disruptor 50, and TIME Magazine's 100 Most Influential Companies.

About the Role
We seek a Senior Product Designer motivated by the opportunity to learn from an exceptional team and deliver delightful experiences for our customers—a ground floor opportunity in a design-driven company. Join a hyper-collaborative design team and get autonomy, ownership, and significant impact across the entire product. You will evolve our design process, own entire projects, and contribute to the development of a diverse team that champions our users' voices and ships incredible experiences every day.

This is a role for an individual contributor with a genuine desire to learn from the best, champion our users' voices, move fast, own decisions, and ship software every day.

What You'll Do
• Lead design and user experience for significant parts of our core product; contribute to the design process from prioritization through launch
• Work directly with Principal Designers, Product, and Engineering leads to transforming company strategy into prioritized initiatives in the roadmap and identifying the right problems to solve
• Be comfortable with breaking down ambiguous problems, ruthlessly prioritizing the highest impact work, and aligning a cross-functional team around it
• Sweat the details, strive for simplicity, and tie together interconnected projects to make complex tasks approachable
• Be opinionated and leverage a direct line to our customers to get insights. Explore solutions, create prototypes, and dive into user feedback/data to identify points of friction and opportunities for improvement for every initiative
• Advocate for our users by continuously iterating on and evolving our core products and experiences. Identify areas of improvement and deliver value to customers every day
• Collaborate with the rest of the design team to evolve our long-term product vision, design systems, brand guidelines, and internal design collaboration process
• Participate in day-to-day team rituals like design crit, story creation, weekly stand-ups, etc
• Help us hire more amazing people to grow the design team and bring diverse perspectives to our design process
• Contribute to developing our design internship program, where you will mentor the next generation of product designers

What You Need
• Minimum 5 years of tech/startup or digital product agency experience, or equivalent
• Demonstrated experience with end-to-end (hybrid UX and UI) product design
• Experience working with developers and PMs building and shipping applications or software
• Extraordinary visual design skills and strong understanding of UX principles
• Background in visual/interaction design, UX/UI, branding & identity, and typography
• Experience with low and high-fidelity prototyping
• Excellent communication and collaboration skills

Nice-to-Haves
• A strong understanding of software engineering workflows and principles is encouraged
• Kind, curious, generous, low ego, and a great sense of humor

Benefits (for U.S.-based full-time employees)
• 100% medical, dental & vision insurance coverage for you
• Partially covered for your dependents
• One Medical annual membership
• 401k (including employer match on contributions made while employed by Ramp)
• Flexible PTO
• Fertility HRA (up to $5,000 per year)
• WFH stipend to support your home office needs
• Wellness stipend
• Parental Leave
• Relocation support for NY
• Pet insurance

Other notices
Pursuant to the San Francisco Fair Chance Ordinance, we will consider for employment qualified applicants with arrest and conviction records.`,
  },

  // Add more hard-coded jobs as needed
];

function JobListings() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [initialFiltersSet, setInitialFiltersSet] = useState(false);
  const [filters, setFilters] = useState(() => {
    const savedFilters = sessionStorage.getItem('jobFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      country: 'United States',
      cityState: '',
      role: '',
      company: '',
      remote: false
    };
  });

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const navigate = useNavigate();

  const RAPID_API_KEY = process.env.REACT_APP_RAPID_API_KEY;
  const apiUrl = 'https://jobs-api14.p.rapidapi.com/list';
  const query = 'Product Designer'; // Use this in the params
  // Remove this line: const location = 'United States'; // Use this in the params

  const [searchSession, setSearchSession] = useState(0);
  const [selectedJob, setSelectedJob] = useState(null);

  // Add this state to track when filters are being applied
  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  // Replace isInitialLoading with hasLoadedJobs
  const [hasLoadedJobs, setHasLoadedJobs] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);

  const fetchJobs = useCallback(async ({ pageParam = 0 }) => {
    try {
      console.log('API request starting, page:', pageParam);
      
      // If we're on the first page and don't have an API key, just return hard-coded jobs
      if (pageParam === 0 && !RAPID_API_KEY) {
        console.log('No API key found, returning only hard-coded jobs');
        return {
          jobs: HARD_CODED_JOBS,
          nextCursor: undefined,
          totalJobs: HARD_CODED_JOBS.length,
          hasMore: false
        };
      }
      
      // Format location properly for the API
      const locationParam = filters.cityState || 'United States';
      console.log('Using location parameter:', locationParam);
      
      const options = {
        method: 'GET',
        url: 'https://jobs-api14.p.rapidapi.com/v2/list',
        params: {
          query: 'Product Designer',
          location: locationParam,
          page: (pageParam + 1).toString(),
          num_pages: '1',
          employment_types: 'FULLTIME,CONTRACTOR,PARTTIME,INTERN',
          date_posted: 'all',
          remote_jobs_only: filters.remote ? 'true' : 'false',
          job_requirements: '',
          radius: '50',
          language: 'en'
        },
        headers: {
          'X-RapidAPI-Key': RAPID_API_KEY,
          'X-RapidAPI-Host': 'jobs-api14.p.rapidapi.com'
        }
      };

      const response = await axios.request(options);
      
      // Log the first job to see its structure
      if (response.data && response.data.jobs && response.data.jobs.length > 0) {
        const firstJob = response.data.jobs[0];
        console.log('First job location fields:', {
          job_city: firstJob.job_city,
          job_state: firstJob.job_state,
          job_country: firstJob.job_country,
          job_location: firstJob.job_location
        });
      }

      if (!response.data) {
        throw new Error('No data received from API');
      }

      const apiJobs = response.data.jobs ? response.data.jobs.map(job => {
        // Determine if job is remote
        const isRemote = 
          job.job_is_remote === true || 
          (job.job_title && job.job_title.toLowerCase().includes('remote')) ||
          (job.job_description && job.job_description.toLowerCase().includes('remote position'));
        
        // Format location properly
        let formattedLocation;
        
        if (isRemote) {
          formattedLocation = 'Remote';
        } else if (job.location) {  // Check for the 'location' field from the API
          formattedLocation = job.location;
        } else if (job.job_location) {  // Fallback to job_location if needed
          formattedLocation = job.job_location;
        } else if (job.job_city && job.job_state) {
          formattedLocation = `${job.job_city}, ${job.job_state}`;
        } else if (job.job_city) {
          formattedLocation = job.job_city;
        } else if (job.job_state) {
          formattedLocation = job.job_state;
        } else if (job.job_country) {
          formattedLocation = job.job_country;
        } else {
          formattedLocation = 'Location not specified';
        }

        // Create a unique ID
        const jobId = job.job_id || `api-${Math.random().toString(36).substr(2, 9)}`;
        
        // Create job data object
        const jobData = {
          id: jobId,
          title: job.job_title || job.title,
          company: job.employer_name || job.company,
          location: formattedLocation,  // Use our formatted location
          description: job.job_description || job.description,
          url: job.job_apply_link || job.url,
          salaryRange: job.job_min_salary && job.job_max_salary
            ? `$${Math.floor(job.job_min_salary).toLocaleString()} - $${Math.floor(job.job_max_salary).toLocaleString()}`
            : job.job_salary || '–',
          employmentType: job.job_employment_type 
            ? job.job_employment_type.split('_').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              ).join(' ')
            : 'Full-time',
          datePosted: job.job_posted_at_datetime_utc
            ? new Date(job.job_posted_at_datetime_utc).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
            : new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              }),
          isHardCoded: false,
          isRemote: isRemote
        };
        
        // Store in localStorage
        const jobsInStorage = JSON.parse(localStorage.getItem('jobs') || '[]');
        if (!jobsInStorage.some(j => j.id === jobId)) {
          jobsInStorage.push(jobData);
          localStorage.setItem('jobs', JSON.stringify(jobsInStorage));
        }
        
        return jobData;
      }) : [];

      // Log sample of processed jobs
      if (apiJobs.length > 0) {
        console.log('Sample processed jobs:');
        apiJobs.slice(0, 3).forEach((job, i) => {
          console.log(`Job ${i+1}:`, {
            title: job.title,
            company: job.company,
            location: job.location
          });
        });
      }

      const allJobs = pageParam === 0 
        ? [...HARD_CODED_JOBS, ...apiJobs]
        : apiJobs;

      const hasMore = response.data.jobs?.length > 0;

      return {
        jobs: allJobs,
        nextCursor: hasMore ? pageParam + 1 : undefined,
        totalJobs: response.data.jobs?.length + (pageParam === 0 ? HARD_CODED_JOBS.length : 0),
        hasMore
      };

    } catch (error) {
      console.error('API Error:', error);
      return {
        jobs: pageParam === 0 ? HARD_CODED_JOBS : [],
        nextCursor: undefined,
        totalJobs: HARD_CODED_JOBS.length,
        hasMore: false
      };
    }
  }, [RAPID_API_KEY, filters]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
    isFetching
  } = useInfiniteQuery(
    ['jobs', filters],
    fetchJobs,
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
      retry: 2,
      onError: (error) => {
        console.error('Query error:', error);
      }
    }
  );

  const allJobs = useMemo(() => {
    if (!data) return HARD_CODED_JOBS;
    
    const apiJobs = data.pages.flatMap(page => 
      page.jobs.filter(job => !job.isHardCoded)
    );
    
    // Remove duplicates based on job ID
    const uniqueJobs = Array.from(
      new Map([...HARD_CODED_JOBS, ...apiJobs].map(job => [job.id, job]))
      .values()
    );
    
    console.log('Combined jobs:', {
      total: uniqueJobs.length,
      hardCoded: HARD_CODED_JOBS.length,
      api: apiJobs.length
    });
    
    return uniqueJobs;
  }, [data]);

  const loadMoreJobs = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      console.log('Loading more jobs...');
      fetchNextPage();
    } else {
      console.log('No more jobs to load or already fetching');
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // Effect to load initial pages
  useEffect(() => {
    const loadInitialPages = async () => {
      if (hasNextPage) {
        await fetchNextPage();
      }
    };

    loadInitialPages();
  }, [fetchNextPage, hasNextPage]);

  const filteredAndSortedJobs = useMemo(() => {
    let jobs = allJobs.filter(job => {
      if (!job) return false;

      const locationMatch = !filters.cityState || (job.location && (
        job.location.toLowerCase() === filters.cityState.toLowerCase() ||
        job.location.toLowerCase().includes(filters.cityState.toLowerCase())
      ));
      
      const roleMatch = !filters.role || 
        (job.title && job.title.toLowerCase().includes(filters.role.toLowerCase()));
      
      const companyMatch = !filters.company || 
        (job.company && job.company.toLowerCase() === filters.company.toLowerCase());
      
      const remoteMatch = !filters.remote || 
        (job.isRemote === true || 
         job.location.toLowerCase().includes('remote'));

      return locationMatch && roleMatch && companyMatch && (!filters.remote || remoteMatch);
    });

    // Sort jobs if needed
    if (sortConfig.key) {
      jobs.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) 
          return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) 
          return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }

    return jobs;
  }, [allJobs, filters, sortConfig]);

  const roles = [
    'Product Designer',
    'Senior Product Designer',
    'Staff Product Designer',
    'Senior Staff Product Designer',
    'Principal Product Designer',
    'Product Design Manager',
    'Director, Product Design',
    'Head of Design',
    'Head of Product Design',
    'UX Designer',
    'User Experience Designer',
    'UI/UX Designer',
    'Interaction Designer',
    'Intern',
    'New grad'
  ];

  const memoizedFilterOptions = useMemo(() => ({
    roles
  }), [roles]);

  // Update handleFilterChange function
  const handleFilterChange = useCallback((filterName, value) => {
    setIsApplyingFilters(true);
    setFilters(prevFilters => {
      const newFilters = {
        ...prevFilters,
        [filterName]: filterName === 'remote' ? !prevFilters.remote : value
      };
      console.log('New filters:', newFilters);
      sessionStorage.setItem('jobFilters', JSON.stringify(newFilters));
      return newFilters;
    });
    setTimeout(() => {
      setIsApplyingFilters(false);
    }, 500);
    console.log(`Filter changed: ${filterName} = ${value}`);
  }, []);

  // Update useEffect for refetching when filters change
  useEffect(() => {
    console.log('Filters changed, refetching...');
    refetch();
  }, [filters, refetch]);

  const onRequestSort = useCallback((key) => {
    console.log('Requesting sort for:', key);
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key && prevConfig.direction === 'ascending') {
        return { key, direction: 'descending' };
      }
      return { key, direction: 'ascending' };
    });
  }, []);

  console.log('Current filters:', filters);
  console.log('Total jobs:', allJobs.length);
  console.log('Filtered jobs:', filteredAndSortedJobs.length);
  console.log('Filtered job list:', filteredAndSortedJobs);

  console.log('Rendering JobTableContainer with jobs:', filteredAndSortedJobs.length);

  React.useEffect(() => {
    console.log('sortConfig changed:', sortConfig);
  }, [sortConfig]);

  React.useEffect(() => {
    console.log('filteredAndSortedJobs changed:', filteredAndSortedJobs.length);
  }, [filteredAndSortedJobs]);

  console.log('Debug info:', {
    pagesLoaded: data?.pages.length || 0,
    hasNextPage,
    isLoadingMore,
    totalJobs: allJobs.length,
    status
  });

  // Update this effect to handle loading states
  useEffect(() => {
    const isInitialFetch = status === 'loading' && !data;
    setIsLoading(isInitialFetch || isFetchingNextPage || isFetching || isApplyingFilters);
    setIsLoadingMore(isFetchingNextPage || isFetching);
    
    if (data?.pages.length > 0 || HARD_CODED_JOBS.length > 0) {
      setHasLoadedJobs(true);
    }
  }, [status, isFetchingNextPage, isFetching, isApplyingFilters, data]);

  useEffect(() => {
    console.log('Job data:', allJobs);
  }, [allJobs]);

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data.pages[0].totalJobs / JOBS_PER_PAGE);
      console.log(`Total Pages Available: ${totalPages}`);
    }
  }, [data]);

  const handleJobClick = useCallback((job) => {
    // Reset scroll position
    window.scrollTo(0, 0);
    
    // Make sure the job is stored in localStorage before navigating
    const jobsInStorage = JSON.parse(localStorage.getItem('jobs') || '[]');
    if (!jobsInStorage.some(j => j.id === job.id)) {
      jobsInStorage.push(job);
      localStorage.setItem('jobs', JSON.stringify(jobsInStorage));
    }
    
    setSelectedJob(job);  // Set the selected job
    
    navigate(`/jobs/${job.id}`, { 
      state: { 
        job,
        preserveFilters: true
      } 
    });

    // Track job click event
    ReactGA.event({
      category: 'Job',
      action: 'Click',
      label: job.title
    });
  }, [navigate]);

  const [showFilters, setShowFilters] = useState(false);
  const [isTitleVisible, setIsTitleVisible] = useState(true);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const scrollThreshold = 50; // Adjust this value to change sensitivity

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(scrollTop - lastScrollTop) > scrollThreshold) {
        if (scrollTop > lastScrollTop) {
          // Scrolling down
          setIsTitleVisible(false);
        } else {
          // Scrolling up
          setIsTitleVisible(true);
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!initialFiltersSet && location.state?.preserveFilters) {
      const savedFilters = sessionStorage.getItem('jobFilters');
      if (savedFilters) {
        setFilters(JSON.parse(savedFilters));
      }
      setInitialFiltersSet(true);
    }
  }, [location.state, initialFiltersSet]);

  // Add this effect to log when data changes
  useEffect(() => {
    if (data) {
      console.log('Data updated:', data);
      console.log('Total pages:', data.pages.length);
      console.log('Total jobs across all pages:', data.pages.reduce((total, page) => total + page.jobs.length, 0));
    }
  }, [data]);

  const handleFeaturedJobsToggle = useCallback((e) => {
    setIsApplyingFilters(true);
    setFilters(prev => {
      const newFilters = {
        ...prev,
        company: e.target.checked ? 'Ramp' : '',
        // Reset other filters when toggling Featured jobs
        cityState: '',
        role: '',
        remote: false
      };
      console.log('New filters after featured toggle:', newFilters);
      return newFilters;
    });
    setTimeout(() => {
      setIsApplyingFilters(false);
    }, 500);
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilters({
      country: 'United States',
      cityState: '',
      role: '',
      company: '',
      remote: false
    });
    sessionStorage.removeItem('jobFilters');
  }, []);

  // Add this function to check if any filters are active
  const areFiltersActive = useCallback(() => {
    return filters.cityState !== '' || 
           filters.role !== '' || 
           filters.company !== '' || 
           filters.remote;
  }, [filters]);

  useEffect(() => {
    if ((data && data.pages.length > 0) || HARD_CODED_JOBS.length > 0) {
      setInitialLoading(false);
    }
  }, [data]);

  console.log('Rendering JobListings component');

  useEffect(() => {
    if (!RAPID_API_KEY) {
      console.error('Missing RAPID_API_KEY');
    } else {
      console.log('API Key exists:', !!RAPID_API_KEY);
      console.log('API Key length:', RAPID_API_KEY.length);
    }
  }, [RAPID_API_KEY]);

  // Add this effect to log sample job data
  useEffect(() => {
    if (data && data.pages && data.pages.length > 0 && data.pages[0].jobs) {
      // Log a sample of job data to debug
      const sampleJob = data.pages[0].jobs[0];
      if (sampleJob) {
        console.log('Sample job data:', {
          title: sampleJob.title,
          company: sampleJob.company,
          location: sampleJob.location,
          isRemote: sampleJob.isRemote,
          id: sampleJob.id
        });
      }
    }
  }, [data]);

  return (
    <div className="job-listings">
      <Helmet>
        <title>Product Designer Jobs | PD JOBS</title>
        <meta name="description" content="Find the latest Product Designer job opportunities. Browse through hundreds of openings from top companies like Ramp, Google, and more." />
        <meta name="keywords" content="product designer jobs, UX designer, UI designer, tech jobs, Ramp, Google, Amazon" />
        <link rel="canonical" href="https://yourwebsite.com/jobs" />
        <meta property="og:title" content="Product Designer Jobs | PD JOBS" />
        <meta property="og:description" content="Find the latest Product Designer job opportunities. Browse through hundreds of openings from top companies like Ramp, Google, and more." />
        <meta property="og:url" content="https://yourwebsite.com/jobs" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="scrollable-content">
        <header className="header">
          <button className="filters-button" onClick={toggleFilters}>
            {showFilters ? "Hide filters" : "Filters"}
          </button>
          
          <div className="cube-container">
            <div className={`mobile-title ${isTitleVisible ? '' : 'hidden'}`}>PD JOBS</div>
            <div className="cube-wrapper">
              <div className="cube" onClick={() => window.location.reload()}>
                <div className="cube-face front"></div>
                <div className="cube-face back"></div>
                <div className="cube-face right"></div>
                <div className="cube-face left"></div>
                <div className="cube-face top"></div>
                <div className="cube-face bottom"></div>
              </div>
              <div className="cube-tooltip">PD JOBS</div>
            </div>
          </div>
          
        </header>
        <div className="job-table-container">
          <div className={`filters ${showFilters ? 'show' : ''}`}>
            <div className="filter-dropdown">
              <select
                name="country"
                value={filters.country}
                onChange={handleFilterChange}
                className="filter-select"
              >
                <option value="United States">United States</option>
              </select>
            </div>
            <div className="filter-dropdown">
              <select
                name="cityState"
                value={filters.cityState}
                onChange={(e) => handleFilterChange('cityState', e.target.value)}
                className={`filter-select ${!filters.cityState ? 'placeholder' : ''}`}
              >
                <option value="" disabled hidden>Select city</option>
                <option value="">All cities</option>
                {CITIES.map(city => (
                  <option key={`${city.name}-${city.state}`} value={`${city.name}, ${city.state}`}>
                    {city.name}, {city.state}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-dropdown">
              <select
                name="role"
                value={filters.role}
                onChange={(e) => handleFilterChange('role', e.target.value)}
                className={`filter-select ${!filters.role ? 'placeholder' : ''}`}
              >
                <option value="" disabled hidden>Select role</option>
                <option value="">All roles</option>
                {roles.map(role => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-dropdown">
              <select
                name="company"
                value={filters.company}
                onChange={(e) => handleFilterChange('company', e.target.value)}
                className={`filter-select ${!filters.company ? 'placeholder' : ''}`}
              >
                <option value="" disabled hidden>Select company</option>
                <option value="">All companies</option>
                {TOP_COMPANIES.map(company => (
                  <option key={company} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-options">
              <div className="remote-toggle">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="remote"
                    checked={filters.remote}
                    onChange={() => handleFilterChange('remote')}
                    id="remote-toggle"
                  />
                  <span className="toggle-slider"></span>
                </label>
                <label 
                  htmlFor="remote-toggle" 
                  className={`toggle-label ${filters.remote ? 'on' : 'off'}`}
                >
                  Remote
                </label>
              </div>
              <div className="remote-toggle">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="featured"
                    checked={filters.company === 'Ramp'}
                    onChange={handleFeaturedJobsToggle}
                    id="featured-toggle"
                  />
                  <span className="toggle-slider"></span>
                </label>
                <label 
                  htmlFor="featured-toggle" 
                  className={`toggle-label ${filters.company === 'Ramp' ? 'on' : 'off'}`}
                >
                  Featured
                </label>
              </div>
            </div>
          </div>
          <div className="job-content">
            {console.log('Rendering job content, initialLoading:', initialLoading)}
            {initialLoading && !HARD_CODED_JOBS.length ? (
              <div className="loading-indicator">
                <p>Loading jobs<AnimatedEllipsis /></p>
              </div>
            ) : status === 'error' ? (
              <div className="error-message">Error loading jobs. Please try again.</div>
            ) : filteredAndSortedJobs.length === 0 ? (
              <div className="no-jobs-message">No jobs found. Try adjusting your filters.</div>
            ) : (
              <JobTableContainer
                jobs={filteredAndSortedJobs}
                onJobClick={handleJobClick}
                sortConfig={sortConfig}
                onRequestSort={onRequestSort}
              />
            )}
          </div>
        </div>
      </div>
      {!initialLoading && (
        <div className="status-bar">
          <div className="status-bar-content">
            <div className="job-count">
              {filteredAndSortedJobs.length} jobs
            </div>
            <div className="status-bar-center">
              {(isLoadingMore || isApplyingFilters) && (
                <div className="loading-indicator">
                  <AnimatedEllipsis />
                </div>
              )}
            </div>
            <div className="status-bar-right">
              {areFiltersActive() && filteredAndSortedJobs.length > 0 && (
                <button 
                  className="load-more-button clear-filters-button"
                  onClick={handleClearFilters}
                >
                  Clear
                </button>
              )}
              {hasNextPage && (
                <button 
                  className="load-more-button"
                  onClick={loadMoreJobs}
                >
                  Load more
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(JobListings);