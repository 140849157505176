import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './JobDetails.css';

// Helper function to decode HTML entities
const decodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

export default function JobDetails() {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [processedDescription, setProcessedDescription] = useState('');

  useEffect(() => {
    const storedJobs = JSON.parse(localStorage.getItem('jobs') || '[]');
    console.log('Looking for job with ID:', id);
    console.log('Jobs in storage:', storedJobs.length);
    
    const foundJob = storedJobs.find((jobItem) => jobItem.id === id);
    console.log('Found job:', foundJob ? 'Yes' : 'No');
    
    setJob(foundJob);

    if (foundJob && foundJob.description) {
      const lines = foundJob.description.split('\n');
      const uniqueLines = lines.filter((line, index, self) =>
        index === self.findIndex((t) => t.trim() === line.trim())
      );
      setProcessedDescription(uniqueLines.join('\n'));
    }
  }, [id]);

  if (!job) return <div>Job not found</div>;

  const handleApply = () => {
    if (job.isHardCoded && job.url) {
      window.open(job.url, '_blank', 'noopener,noreferrer');
    } else if (job.jobProviders && job.jobProviders.length > 0 && job.jobProviders[0].url) {
      window.open(job.jobProviders[0].url, '_blank', 'noopener,noreferrer');
    } else {
      alert('Application link not available for this job.');
    }
  };

  const renderDescription = (description) => {
    if (!description) return <p>No description available.</p>;
    
    const paragraphs = description.split(/\r?\n/);
    let inList = false;
    let listItems = [];

    return paragraphs.map((paragraph, index) => {
      const decodedParagraph = decodeHTMLEntities(paragraph);
      const trimmedParagraph = decodedParagraph.trim();

      // Updated title detection
      if (trimmedParagraph.length > 0 && (
          trimmedParagraph.endsWith(':') || 
          trimmedParagraph === trimmedParagraph.toUpperCase() ||
          /^[A-Z][A-Za-z\s,'-]+:?$/.test(trimmedParagraph) ||
          /^[A-Z\s]+$/.test(trimmedParagraph) ||
          /^(About The Role|What You'll Do|What You Need|Nice-to-Haves|Qualifications|Requirements|Responsibilities|What It Takes|Benefits)/.test(trimmedParagraph)
      )) {
        return (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            <h3 className="job-description-title">{trimmedParagraph}</h3>
          </React.Fragment>
        );
      } else if (trimmedParagraph.match(/^[•\-*]\s/)) {
        // Bullet point handling
        if (!inList) {
          inList = true;
          listItems = [];
        }
        listItems.push(trimmedParagraph.replace(/^[•\-*]\s/, ''));
        
        // If this is the last paragraph or the next one isn't a list item, render the list
        if (index === paragraphs.length - 1 || !paragraphs[index + 1].trim().match(/^[•\-*]\s/)) {
          const list = (
            <ul key={index} className="job-description-list">
              {listItems.map((item, itemIndex) => (
                <li key={itemIndex} className="job-description-list-item">{item}</li>
              ))}
            </ul>
          );
          inList = false;
          return list;
        }
        return null;
      } else {
        // Normal paragraph or empty line
        inList = false;
        return trimmedParagraph ? 
          <p key={index} className="job-description-paragraph">{decodedParagraph}</p> :
          <br key={index} />;
      }
    }).filter(Boolean); // Remove null entries
  };

  const displayValue = (value, isSalary = false) => {
    if (!value) return '—';
    const decodedValue = decodeHTMLEntities(value);
    return isSalary ? `$${decodedValue}` : decodedValue;
  };

  return (
    <div className="job-detail">
      <Link to="/" className="back-button">Back</Link>
      <button onClick={handleApply} className="apply-button">Apply</button>
      <h1 className="job-detail-title">{displayValue(job.title)}</h1>
      
      <table className="job-detail-table">
        <tbody>
          <tr>
            <td className="job-detail-label">Company:</td>
            <td className="job-detail-value">{displayValue(job.company)}</td>
          </tr>
          <tr>
            <td className="job-detail-label">Location:</td>
            <td className="job-detail-value">{displayValue(job.location)}</td>
          </tr>
          <tr>
            <td className="job-detail-label">Employment type:</td>
            <td className="job-detail-value">{displayValue(job.employmentType)}</td>
          </tr>
          <tr>
            <td className="job-detail-label">Date posted:</td>
            <td className="job-detail-value">{displayValue(job.datePosted)}</td>
          </tr>
          <tr>
            <td className="job-detail-label">Salary range:</td>
            <td className="job-detail-value">{displayValue(job.salaryRange, true)}</td>
          </tr>
        </tbody>
      </table>

      <div className="job-detail-description">
        {renderDescription(processedDescription)}
      </div>
    </div>
  );
}
